@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");

/* --- VARIABLES --- */
:root {
  --bg-color: #101010;
  --hl-color: #009e66;
  --hov-color: #03b979;
  --text-color: #f3f3f3;
  --secondary-text-color: #9f9f9f;
  --grey: #9f9f9f76;
  --spacing: 50px;
  --sm-spacing: 30px;
}

/* --- ANIMATIONS --- */

@keyframes slideDown {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(1%);
    opacity: 1;
  }
}

/* --- RESETS --- */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  background-color: var(--bg-color);
}

body {
  font-family: "Poppins", sans-serif;
}

/* --- GLOBAL STYLES --- */

::selection {
  background-color: var(--hl-color);
  color: var(--text-color);
}
.pageTitle {
  color: var(--text-color);
  font-size: 36px;
  border-bottom: 3px solid var(--hl-color);
  margin-bottom: var(--spacing);
}

.pageSubTitle {
  color: var(--text-color);
  font-size: 24px;
  /* border-bottom: 3px solid var(--hl-color); */
  margin-bottom: var(--spacing);
}

.pageDescription {
  color: var(--grey);
  font-size: 14px;
}

.socialIcons .icon {
  color: var(--text-color);
}

.socialIcons .icon:hover {
  scale: 1.2;
  color: var(--hl-color);
}



.btn {
  color: #f9f9f9;
  background-color: var(--hl-color);
  border: none;
  font-size: 14px;
  padding: 12px var(--sm-spacing);
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.15);
}

.btn:hover {
  background-color: var(--hov-color);
}

/* --- MOBILE STYLES --- */

/* --- HEADER ---  */
header {
  padding: var(--sm-spacing) var(--spacing);
  color: var(--text-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}

.logo {
  height: 30px;
}

.logo_first_letter {
  color: var(--hl-color);
 
}

.logo_second_letter { 
  color: var(--text-color);
}

.logo-link {
  text-decoration: none;
}

.links {
  display: none;
  position: absolute;
  top: 93px;
  right: 0px;
  background-color: var(--bg-color);
  width: 100%;
  padding: 10px;
  z-index: 999;
  border-radius: 10px;
}

.links a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 15px;
  display: flex;
  justify-content: center;
  padding: 20px 0;
  position: relative;
}

.links a:hover {
  color: white;
}

.links.open {
  display: block;
  animation: slideDown 0.5s ease-in-out forwards;
}

.dropdown-toggle {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  z-index: 999;
}

.openMenu,
.closeMenu {
  height: 20px;
  transition: transform 0.5s ease-in-out;
}

.closeMenu {
  transform: rotate(180deg);
  cursor: pointer;
}

.links .closed {
  background-color: transparent;
}

/* --- MEDIA QUERIES --- */
@media (min-width: 750px) {
  .links {
    display: flex;
    background-color: var(--bg-color);
    justify-content: flex-end;
    position: relative;
    top: auto;
    width: 0;
    left: 0;
    padding: 0 10px;
  }

  .links a {
    padding: 0px;
    margin-left: 3px;
  }

  .links a:not(:last-child) {
    margin-right: 20px;
  }

  .links.open {
    display: flex;
  }

  .dropdown-toggle {
    display: none;
  }

  .links a:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--text-color);
    transition: width 0.3s ease-in-out;
  }

  .links a:hover:before {
    width: 100%;
  }

  .links a.active:before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--text-color);
    transition: width 0.3s ease-in-out;
    width: 100%;
  }

  .closed {
    background-color: transparent;
  }
}

.fixed-plugin {
  position: fixed;
  top: 30%;
  right: 0;
  z-index: 1031;
}

.fixed-plugin .fa-palette {
  color: white;
  background-color: #333;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.fixed-plugin .fa-palette:hover {
  transform: scale(1.1); /* Scale the icon by 1.1 times on hover */
}

.dropdown-menu {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden; /* Added visibility property */
  background-color: #fff;
}

.dropdown-menu.show {
  transform: translateX(0);
  opacity: 1;
  visibility: visible; /* Added visibility property */
}

.header-title {
  padding: 10px;
}

.badge-colors .active {
  border: 2px solid #111;
}

.badge {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  margin: 2px;
}

/* Define a new CSS class for the badges */
.badge-style {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  vertical-align: middle;
}

/* If your Badge component has a specific class, you can set the backgroundColor using a CSS variable for more dynamic styling */
.badge-style {
  background-color: var(--badge-color);
}

/* CSS for the check icon */
.check-icon {
  color: #FFF;
}
